import { z } from 'zod'

const UserIdSchema = z.string()
const BaseEventSchema = z.object({
  userId: UserIdSchema,
})

export const HeartbeatEventSchema = BaseEventSchema.merge(
  z.object({ event: z.literal('heartbeat') })
)

export const InvalidateQueriesEventSchema = BaseEventSchema.merge(
  z.object({
    event: z.literal('invalidateQueries'),
    invalidateQueries: z.any(),
  })
)

export const EventSchema = z.discriminatedUnion('event', [
  HeartbeatEventSchema,
  InvalidateQueriesEventSchema,
])

export type HeartbeatEvent = z.infer<typeof HeartbeatEventSchema>
export type InvalidateQueriesEvent = z.infer<typeof InvalidateQueriesEventSchema>
export type Event = z.infer<typeof EventSchema>
