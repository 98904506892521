import { MarkMessageAsUnreadRequest, MarkMessageAsUnreadResponse } from '@eigtech/inbox-types'
import { contextualPost, useMutation } from '@eigtech/ui-shared-api'
import { asyncForEach, wait } from '@eigtech/ui-shared-dave'
import { chunk } from 'lodash-es'
import { inboxBasePath } from './constants'
import { useInvalidateInboxSummary } from './getInboxSummary'
import { useInvalidateMessage } from './getMessage'
import { useInvalidateMessages } from './getMessages'
import { useGetUserInboxId } from './useGetUserInboxId'

const markMessageAsUnread = ({ inboxId, messageId }: MarkMessageAsUnreadRequest) =>
  contextualPost<MarkMessageAsUnreadResponse>(
    `${inboxBasePath}/${inboxId}/${messageId}/unread`,
    null,
    { responseType: 'none' }
  )

export function useMarkMessageAsUnread() {
  const inboxId = useGetUserInboxId()

  const invalidateMessage = useInvalidateMessage()
  const invalidateMessages = useInvalidateMessages()
  const invalidateInboxSummary = useInvalidateInboxSummary()

  return useMutation({
    mutationFn: ({ messageId }: Pick<MarkMessageAsUnreadRequest, 'messageId'>) =>
      markMessageAsUnread({ inboxId, messageId }),
    onSettled(__data, __error, { messageId }) {
      invalidateMessage({ messageId })
      invalidateMessages()
      invalidateInboxSummary()
    },
  })
}

export function useMarkMessagesAsUnread() {
  const inboxId = useGetUserInboxId()

  const invalidateMessage = useInvalidateMessage()
  const invalidateMessages = useInvalidateMessages()
  const invalidateInboxSummary = useInvalidateInboxSummary()

  return useMutation({
    async mutationFn({ messageIds }: { messageIds: string[] }) {
      const batchedMessages = chunk(messageIds, 10)

      await asyncForEach(batchedMessages, async (batch) => {
        const promises = batch.map((messageId) => markMessageAsUnread({ inboxId, messageId }))
        await Promise.all(promises)
        await wait(100)
      })
    },
    onSuccess(__data, { messageIds }) {
      invalidateMessages()
      invalidateInboxSummary()

      messageIds.forEach((messageId) => {
        invalidateMessage({ messageId })
      })
    },
  })
}
