import { ArchiveAllMessagesRequest, ArchiveAllMessagesResponse } from '@eigtech/inbox-types'
import { contextualPost, useMutation } from '@eigtech/ui-shared-api'
import { inboxBasePath } from './constants'
import { useInvalidateInboxSummary } from './getInboxSummary'
import { useInvalidateMessages } from './getMessages'
import { useGetUserInboxId } from './useGetUserInboxId'

const archiveAllMessages = ({ inboxId }: ArchiveAllMessagesRequest) =>
  contextualPost<ArchiveAllMessagesResponse>(`${inboxBasePath}/archive/${inboxId}`, null, {
    responseType: 'none',
  })

export function useArchiveAllMessages() {
  const invalidateMessages = useInvalidateMessages()
  const invalidateInboxSummary = useInvalidateInboxSummary()

  const inboxId = useGetUserInboxId()

  return useMutation({
    mutationFn: () => archiveAllMessages({ inboxId }),
    onSuccess() {
      invalidateMessages()
      invalidateInboxSummary()
    },
  })
}
