import { ApiSchema } from '@eigtech/gatekeeper-types'
import { ApiLambdalerAuthHeaderSchema } from '@eigtech/lambdaler-api-gateway-types'
import { CornSchema } from '@eigtech/shared-corn'
import { Handler } from 'aws-lambda'
import { z } from 'zod'

// get document url
export const GetDocumentUrlApiSchema = {
  headers: ApiLambdalerAuthHeaderSchema,
  params: z.object({
    entityId: CornSchema,
    documentCorn: z.string(),
  }),
} satisfies ApiSchema

export const GetDocumentUrlRequestSchema = GetDocumentUrlApiSchema.params.merge(
  GetDocumentUrlApiSchema.headers
)

export type GetDocumentUrlRequest = z.infer<typeof GetDocumentUrlRequestSchema>
export type GetDocumentUrlResponse = string
export type GetDocumentUrlHandler = Handler<GetDocumentUrlRequest, GetDocumentUrlResponse>
