import { MarkForPublicationRequest } from '@eigtech/documents-types'
import { DocumentV2 } from '@eigtech/documents-types'
import { ensureDocumentCorn } from '@eigtech/documents-util'
import {
  Center,
  ComposedAlert,
  ComposedDrawerWrapperProps,
  Tooltip,
  WithDrawerErrorBoundaryProps,
  chakra,
  useToast,
  withDrawerErrorBoundary,
} from '@eigtech/ui-shared-dave'
import { FormDrawer, createForm } from '@eigtech/ui-shared-forms'
import { z } from 'zod'
import { useMarkDocumentForPublication } from '../../api/publishDocument'
import { useGetDocumentUrl } from '../../api/getDocumentUrl'
import { DATE_TIME_INPUT_FORMAT, format } from '@eigtech/ui-shared-dates'

export type ConvertVideoDrawerProps = ComposedDrawerWrapperProps & {
  document: DocumentV2
  forceDateInputValueToPreferredTimezone?: (date: Date) => Date
  timezoneAbbreviation?: string
}

export const ConvertVideoToMeetingDrawer = withDrawerErrorBoundary(
  function ExportDocumentDrawer({
    document,
    forceDateInputValueToPreferredTimezone = (date: Date) => date,
    timezoneAbbreviation,
    ...props
  }: ConvertVideoDrawerProps) {
    const { data: url } = useGetDocumentUrl({
      entityId: document.entityId,
      documentId: document.id,
    })

    const form = useForm({
      defaultValues: {
        startTime: format(new Date(), "yyyy-MM-dd'T'HH:mm"),
      },
    })

    const toast = useToast()
    const { mutateAsync: exportDocuments, isError } = useMarkDocumentForPublication()

    async function handleConvert({ startTime }: ConvertVideoForm) {
      const target = 'meetings'
      const payload: MarkForPublicationRequest = {
        documentCorn: ensureDocumentCorn(document.id),
        entityId: document.entityId,
        publicationTargets: [target],
        publicationAttributes: {
          additionalCohorts: [],
          entityId: target,
          startTime: forceDateInputValueToPreferredTimezone(new Date(startTime)).toISOString(),
        },
      }

      await exportDocuments(payload)

      toast({
        status: 'success',
        title: `Successfully converted video to meeting`,
        description: `It will take a few minutes for selected video to be fully converted and available in meetings.`,
      })

      props.onClose()
    }

    return (
      <FormDrawer
        {...baseProps}
        {...props}
        form={form}
        title={`Convert video to meeting`}
        onSubmit={handleConvert}
      >
        {!!url && (
          <Tooltip label={document.filename}>
            <Center>
              <chakra.video src={url} w="full" />
            </Center>
          </Tooltip>
        )}
        <InputField
          inputProps={{
            max: format(new Date(), DATE_TIME_INPUT_FORMAT),
          }}
          label={`Meeting Start Time${!!timezoneAbbreviation ? ` (${timezoneAbbreviation})` : ''}`}
          name="startTime"
          type="datetime-local"
        />
        {isError && (
          <ComposedAlert
            alert={{
              title: 'Something went wrong',
              description:
                'We could not convert this video. Please try again. If the issue persists, please contact EIG Customer Service.',
            }}
            status="error"
          />
        )}
      </FormDrawer>
    )
  },
  () => baseProps
)

const baseProps = { title: 'Convert video to meeting' } satisfies WithDrawerErrorBoundaryProps

const ConvertVideoFormSchema = z.object({
  startTime: z.string().min(1),
})

type ConvertVideoForm = z.infer<typeof ConvertVideoFormSchema>

const { useForm, InputField } = createForm(ConvertVideoFormSchema, 'convertVideoForm')
