import { MarkMessageAsReadRequest, MarkMessageAsReadResponse } from '@eigtech/inbox-types'
import { contextualPost, useMutation } from '@eigtech/ui-shared-api'
import { asyncForEach, wait } from '@eigtech/ui-shared-dave'
import { chunk } from 'lodash-es'
import { inboxBasePath } from './constants'
import { useInvalidateInboxSummary } from './getInboxSummary'
import { useInvalidateMessage } from './getMessage'
import { useInvalidateMessages } from './getMessages'
import { useGetUserInboxId } from './useGetUserInboxId'

const markMessageAsRead = ({ inboxId, messageId }: MarkMessageAsReadRequest) =>
  contextualPost<MarkMessageAsReadResponse>(`${inboxBasePath}/${inboxId}/${messageId}/read`, null, {
    responseType: 'none',
  })

export function useMarkMessageAsRead() {
  const inboxId = useGetUserInboxId()

  const invalidateMessage = useInvalidateMessage()
  const invalidateMessages = useInvalidateMessages()
  const invalidateInboxSummary = useInvalidateInboxSummary()

  return useMutation({
    mutationFn: ({ messageId }: Pick<MarkMessageAsReadRequest, 'messageId'>) =>
      markMessageAsRead({ inboxId, messageId }),
    onSuccess(__data, { messageId }) {
      setTimeout(() => {
        invalidateMessage({ messageId })
        invalidateMessages()
        invalidateInboxSummary()
      }, 100)
    },
  })
}

export function useMarkMessagesAsRead() {
  const inboxId = useGetUserInboxId()

  const invalidateMessage = useInvalidateMessage()
  const invalidateMessages = useInvalidateMessages()
  const invalidateInboxSummary = useInvalidateInboxSummary()

  return useMutation({
    async mutationFn({ messageIds }: { messageIds: string[] }) {
      const batchedMessages = chunk(messageIds, 10)

      await asyncForEach(batchedMessages, async (batch) => {
        const promises = batch.map((messageId) => markMessageAsRead({ inboxId, messageId }))
        await Promise.all(promises)
        await wait(100)
      })
    },
    onSuccess(__data, { messageIds }) {
      invalidateMessages()
      invalidateInboxSummary()

      messageIds.forEach((messageId) => {
        invalidateMessage({ messageId })
      })
    },
  })
}
