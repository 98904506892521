import { ApiSchema } from '@eigtech/gatekeeper-types'
import { ApiLambdalerAuthHeaderSchema } from '@eigtech/lambdaler-api-gateway-types'
import { CornSchema } from '@eigtech/shared-corn'
import { Handler } from 'aws-lambda'
import { z } from 'zod'

export const GetTranscriptUrlApiSchema = {
  headers: ApiLambdalerAuthHeaderSchema,
  params: z.object({
    entityId: CornSchema,
    meetingId: z.string(),
  }),
  response: z.object({
    url: z.string(),
  }),
} satisfies ApiSchema

export const GetTranscriptUrlRequestSchema = GetTranscriptUrlApiSchema.params.merge(
  GetTranscriptUrlApiSchema.headers
)

export type GetTranscriptUrlRequest = z.infer<typeof GetTranscriptUrlRequestSchema>
export type GetTranscriptUrlResponse = z.infer<typeof GetTranscriptUrlApiSchema.response>

export interface GetTranscriptUrlHandler
  extends Handler<GetTranscriptUrlRequest, GetTranscriptUrlResponse> {}
