import {
  GetMeetingRequest,
  GetMeetingResponse,
  ListMeetingScreenshotsRequest,
  ListMeetingScreenshotsResponse,
  UpdateScreenshotDescriptionRequest,
} from '@eigtech/meetings-types'
import { contextualGet, queryOptions, useQuery, useQueryClient } from '@eigtech/ui-shared-api'
import { useCallback } from 'react'
import { QueryKeyMeetingDetail, meetingsBasePath, meetingsQueryKeys } from './constants'

const getMeeting = ({ entityId, meetingId, startTime }: GetMeetingRequest) =>
  contextualGet<GetMeetingResponse>(
    `${meetingsBasePath}/meeting/${entityId}/${startTime}/${meetingId}`
  )

export const getMeetingQueryOptions = ({ entityId, meetingId, startTime }: GetMeetingRequest) =>
  queryOptions({
    queryKey: meetingsQueryKeys.detail({ entityId, meetingId }),
    queryFn: () => getMeeting({ entityId, meetingId, startTime }),
    enabled: !!(entityId && meetingId && startTime),
  })

export const useGetMeeting = (request: GetMeetingRequest) =>
  useQuery(getMeetingQueryOptions(request))

export function useInvalidateMeetingsDetails() {
  const queryClient = useQueryClient()

  return function invalidateMeetingsDetails(request: QueryKeyMeetingDetail) {
    queryClient.invalidateQueries({ queryKey: meetingsQueryKeys.details(request) })
  }
}

export function useInvalidateGetMeeting() {
  const queryClient = useQueryClient()

  return function invalidateGetMeeting(request: QueryKeyMeetingDetail) {
    queryClient.invalidateQueries({ queryKey: meetingsQueryKeys.detail(request) })
  }
}

//

const getMeetingUploads = ({ entityId, startTime }: ListMeetingScreenshotsRequest) =>
  contextualGet<ListMeetingScreenshotsResponse>(
    `${meetingsBasePath}/meeting/screenshots/${entityId}/${startTime}`
  )

export const getMeetingUploadsQueryOptions = ({
  meetingId,
  entityId,
  startTime,
}: ListMeetingScreenshotsRequest & { meetingId: string }) =>
  queryOptions({
    queryKey: meetingsQueryKeys.uploads({ entityId, meetingId }),
    queryFn: () => getMeetingUploads({ entityId, startTime }),
  })

export const useGetMeetingUploads = (
  request: ListMeetingScreenshotsRequest & { meetingId: string }
) => useQuery(getMeetingUploadsQueryOptions(request))

export function useInvalidateGetMeetingUploads() {
  const queryClient = useQueryClient()

  return function invalidateGetMeetingUploads(request: QueryKeyMeetingDetail) {
    queryClient.invalidateQueries({ queryKey: meetingsQueryKeys.uploads(request) })
  }
}

export function useOptimisticSetUploadDescription() {
  const invalidateMeetingUploads = useInvalidateGetMeetingUploads()
  const queryClient = useQueryClient()

  return useCallback(
    async ({
      meetingId,
      ...request
    }: UpdateScreenshotDescriptionRequest & { meetingId: string }) => {
      const queryKey = meetingsQueryKeys.uploads({ entityId: request.entityId, meetingId })
      await queryClient.cancelQueries({ queryKey })
      const prevUploads = queryClient.getQueryData<ListMeetingScreenshotsResponse>(queryKey)

      if (!!prevUploads) {
        queryClient.setQueryData<ListMeetingScreenshotsResponse>(queryKey, () => {
          const uploadIndex = prevUploads.findIndex((pu) => pu.dateAdded === request.dateAdded)
          if (uploadIndex === undefined) return
          prevUploads[uploadIndex].label = request.label
          prevUploads[uploadIndex].description = request.description
          return prevUploads
        })
      }

      setTimeout(() => {
        invalidateMeetingUploads({ entityId: request.entityId, meetingId })
      }, 1500)
    },
    [invalidateMeetingUploads, queryClient]
  )
}
