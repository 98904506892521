import { Handler } from 'aws-lambda'
import z from 'zod'
import { GroupIdSchema, RecipientIdSchema } from '../../entity'
import { ApiSchema } from '@eigtech/gatekeeper-types'

export const AddRecipientToMessageGroupRequestSchema = z.object({
  groupId: GroupIdSchema,
  recipientId: RecipientIdSchema,
})

export const AddRecipientToMessageGroupResponseSchema = z.void()

export type AddRecipientToMessageGroupRequest = z.infer<
  typeof AddRecipientToMessageGroupRequestSchema
>

export type AddRecipientToMessageGroupResponse = z.infer<
  typeof AddRecipientToMessageGroupResponseSchema
>

export interface AddRecipientToMessageGroupHandler
  extends Handler<AddRecipientToMessageGroupRequest, AddRecipientToMessageGroupResponse> {}

export const AddRecipientToMessageGroupApiSchema: ApiSchema = {
  body: AddRecipientToMessageGroupRequestSchema,
}
