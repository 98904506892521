import {
  GetDocumentPublicationsRequest,
  GetDocumentPublicationsResponse,
} from '@eigtech/documents-types'
import { ensureDocumentCorn } from '@eigtech/documents-util'
import { contextualGet, queryOptions, useQuery, useQueryClient } from '@eigtech/ui-shared-api'
import { documentsBasePath, documentsQueryKeys } from './constants'

const getDocumentPublications = ({ documentCorn, entityId }: GetDocumentPublicationsRequest) =>
  contextualGet<GetDocumentPublicationsResponse>(
    `${documentsBasePath}/${entityId}/${documentCorn}/publications`
  )

export function getDocumentPublicationsQueryOptions({
  documentCorn: documentId,
  entityId,
}: GetDocumentPublicationsRequest) {
  const documentCorn = ensureDocumentCorn(documentId)

  return queryOptions({
    queryKey: documentsQueryKeys.documentPublications({ documentId: documentCorn, entityId }),
    queryFn: () => getDocumentPublications({ documentCorn, entityId }),
  })
}

export const useGetDocumentPublications = (props: GetDocumentPublicationsRequest) =>
  useQuery(getDocumentPublicationsQueryOptions(props))

export function useInvalidateDocumentPublications() {
  const queryClient = useQueryClient()

  return function (props: GetDocumentPublicationsRequest) {
    queryClient.invalidateQueries({
      queryKey: getDocumentPublicationsQueryOptions(props).queryKey,
    })
  }
}
