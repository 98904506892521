import { CornSchema } from '@eigtech/shared-corn'
import { Handler } from 'aws-lambda'
import z from 'zod'

export const UpdateScreenshotDescriptionRequestPathSchema = z.object({
  entityId: CornSchema,
  startTime: z.string().datetime(),
  dateAdded: z.string().datetime(),
})

export const UpdateScreenshotDescriptionRequestBodySchema = z.object({
  description: z.string(),
  label: z.string(),
})

export const UpdateScreenshotDescriptionRequestSchema =
  UpdateScreenshotDescriptionRequestBodySchema.merge(UpdateScreenshotDescriptionRequestPathSchema)

export const UpdateScreenshotDescriptionResponseSchema = z.void()

export type UpdateScreenshotDescriptionRequest = z.infer<
  typeof UpdateScreenshotDescriptionRequestSchema
>
export type UpdateScreenshotDescriptionRequestPath = z.infer<
  typeof UpdateScreenshotDescriptionRequestPathSchema
>
export type UpdateScreenshotDescriptionRequestBody = z.infer<
  typeof UpdateScreenshotDescriptionRequestBodySchema
>
export type UpdateScreenshotDescriptionResponse = z.infer<
  typeof UpdateScreenshotDescriptionResponseSchema
>

export interface UpdateScreenshotDescriptionHandler
  extends Handler<UpdateScreenshotDescriptionRequest, UpdateScreenshotDescriptionResponse> {}
