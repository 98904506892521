import z from 'zod'
import { GroupIdSchema, RecipientIdSchema } from './messageIds'

export const GroupSchema = z.object({
  groupId: GroupIdSchema,
  recipientIds: RecipientIdSchema.array(),
})

export const DatabaseGroupSchema = GroupSchema.merge(
  z.object({
    PK: z.string(),
    SK: z.string(),
    modifiedDate: z.string().datetime(),
    recipientIds: z.set(RecipientIdSchema),
  })
)

export type Group = z.infer<typeof GroupSchema>
export type DatabaseGroup = z.infer<typeof DatabaseGroupSchema>
