import { ApiSchema } from '@eigtech/gatekeeper-types'
import { Handler } from 'aws-lambda'
import { z } from 'zod'
import { Auth0UserSchema } from '../../logStreamEvents/base/base.js'

export const GetUserRequestPathSchema = z.object({
  userId: z.string(),
})
export const GetUserRequestSchema = GetUserRequestPathSchema
export const GetUserResponseSchema = z.object({
  user: Auth0UserSchema,
})
export const GetUserApiSchema = {
  params: GetUserRequestPathSchema,
  response: GetUserResponseSchema,
} satisfies ApiSchema

export type GetUserRequest = z.infer<typeof GetUserRequestSchema>
export type GetUserResponse = z.infer<typeof GetUserResponseSchema>
export type GetUserHandler = Handler<GetUserRequest, GetUserResponse>
