import { Badge, ComposedAlert, Heading, HStack, Stack } from '@eigtech/ui-shared-dave'
import { useGetInboxSummary } from '../api'

export function InboxHeader() {
  const { data: inbox, isPending, isError } = useGetInboxSummary()

  return (
    <Stack>
      <HStack spacing="4">
        <Heading as="h2" size="lg">
          Your Inbox
        </Heading>

        {!(isError || isPending) && (
          <Badge colorScheme="blue">{inbox?.unreadMessages ?? '0'} Unread</Badge>
        )}
      </HStack>

      {isError && <ComposedAlert alert="Could not load inbox information." status="error" />}
    </Stack>
  )
}
