import { GetInboxQueryRequest, GetInboxRequest, GetInboxResponse } from '@eigtech/inbox-types'
import {
  contextualGet,
  infiniteQueryOptions,
  useInfiniteQuery,
  useQueryClient,
} from '@eigtech/ui-shared-api'
import { useCallback } from 'react'
import { inboxBasePath, inboxQueryKeys } from './constants'
import { useGetUserInboxId } from './useGetUserInboxId'

async function getMessages({ inboxId, nextToken, sortByRead, ...request }: GetInboxRequest) {
  const searchParams: GetInboxQueryRequest = request

  if (!!nextToken) {
    searchParams.nextToken = nextToken
  }

  if (!!sortByRead) {
    searchParams.sortByRead = sortByRead
  }

  return contextualGet<GetInboxResponse>(`${inboxBasePath}/${inboxId}`, {
    searchParams,
  })
}

export const getMessagesQueryOptions = (request: GetInboxRequest) =>
  infiniteQueryOptions({
    queryKey: inboxQueryKeys.filteredList(request),
    queryFn: ({ pageParam }) => getMessages({ ...request, nextToken: pageParam }),
    initialPageParam: undefined,
    getNextPageParam: (lastPage) => lastPage.nextToken,
    getPreviousPageParam: () => undefined as string | undefined,
    enabled: !!request.inboxId,
  })

export function useGetMessages(request?: Omit<GetInboxRequest, 'nextToken' | 'inboxId'>) {
  const inboxId = useGetUserInboxId()

  return useInfiniteQuery(getMessagesQueryOptions({ ...request, inboxId }))
}

export function useInvalidateMessages() {
  const queryClient = useQueryClient()

  const invalidateMessages = useCallback(() => {
    return queryClient.invalidateQueries({ queryKey: inboxQueryKeys.list(), refetchType: 'all' })
  }, [queryClient])

  return invalidateMessages
}
