import { CohortEnumSchema } from '@eigtech/auth0-types'

import { CornSchema } from '@eigtech/shared-corn'
import z from 'zod'
import { ChimeLifeCycleEventSchema } from './chime.js'
import { meetingServiceCorn } from './corn.js'
import { ParticipantSchema } from './participant.js'

export const MeetingStatusSchema = z.enum(['active', 'closed'])
export const MeetingStatus = MeetingStatusSchema.Values
export type MeetingStatusEnum = z.infer<typeof MeetingStatusSchema>

export const MeetingDetailsCornSchema = meetingServiceCorn('details')
export const MeetingDetailsSchema = z.object({
  appInstanceArn: z.string().optional(), // optional for hysterical raisins
  chimeChannelArn: z.string().optional(), // optional for hysterical raisins
  chimeEvents: z.array(ChimeLifeCycleEventSchema),
  cohort: CohortEnumSchema,
  description: z.string().optional(),
  endMeetingReason: z.string().optional(),
  endTime: z.string().datetime().optional(),
  entityId: CornSchema, // e.g. claim corn
  entityName: z.string().optional(),
  id: MeetingDetailsCornSchema,
  fromUpload: z.boolean().optional(),
  convertedFromCorn: CornSchema.optional(),
  meetingId: z.string(),
  organizer: z.string(),
  participants: z.array(ParticipantSchema),
  startTime: z.string().datetime(),
  status: MeetingStatusSchema,
  transcriptionStarted: z.boolean().optional(), // optional for hysterical raisins
})

export type MeetingDetails = z.infer<typeof MeetingDetailsSchema>
export type MeetingDetailsCorn = z.infer<typeof MeetingDetailsCornSchema>
export type Participant = z.infer<typeof ParticipantSchema>
