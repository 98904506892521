import { CohortEnumSchema } from '@eigtech/auth0-types'
import { ApiSchema } from '@eigtech/gatekeeper-types'
import { CornSchema } from '@eigtech/shared-corn'
import { Handler } from 'aws-lambda'
import { z } from 'zod'

export const AddDocumentCohortApiSchema = {
  params: z.object({
    entityId: CornSchema,
    cohort: CohortEnumSchema,
    documentCorn: CornSchema,
  }),
} satisfies ApiSchema

export const AddDocumentCohortRequestSchema = AddDocumentCohortApiSchema.params

export type AddDocumentCohortRequest = z.infer<typeof AddDocumentCohortRequestSchema>
export type AddDocumentCohortResponse = void

export type AddDocumentCohortHandler = Handler<AddDocumentCohortRequest, AddDocumentCohortResponse>
