import { getErrorMessage } from '@eigtech/function-utils'
import {
  InboxMessageReceivedNotification,
  NotificationSchema,
  ToastNotification,
} from '@eigtech/partykit-types'
import { minutesToMilliseconds } from '@eigtech/ui-shared-dates'
import {
  ButtonGroup,
  ConfirmModal,
  Stack,
  Text,
  useDisclosure,
  useToast,
} from '@eigtech/ui-shared-dave'
import log from '@eigtech/ui-shared-logging'
import { corrupt, exhaustive } from 'exhaustive'
import { memo, useEffect } from 'react'
import { z } from 'zod'
import { notificationSocket } from './notifications'
import { useLocalStorage } from 'react-use'
import { LinkButton } from '@eigtech/ui-shared-router'
import { useInvalidateInboxSummary, useInvalidateMessages } from '@eigtech/ui-shared-messaging'

const canBrowserNotify = 'Notification' in window

export const NotificationsHandler = memo(function NotificationsHandler() {
  const toast = useToast()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [
    browserNotificationsDisabled,
    setBrowserNotificationsDisabled,
    // removeBrowserNotificationsDisabled,
  ] = useLocalStorage('browser-notifications-disabled', false)
  const invalidateInboxSummary = useInvalidateInboxSummary()
  const invalidateMessages = useInvalidateMessages()

  useEffect(() => {
    if (!canBrowserNotify) return

    if (Notification.permission === 'default' && !browserNotificationsDisabled) {
      onOpen()
    }
  }, [browserNotificationsDisabled, onOpen])

  async function requestPermission() {
    if (!canBrowserNotify) return

    const success = () =>
      toast({ status: 'success', title: 'Successfully subscribed you to browser notifications!' })

    if (Notification.permission === 'granted') {
      success()
      return
    }

    const permission = await Notification.requestPermission()

    if (permission === 'granted') {
      success()
    } else {
      toast({ status: 'warning', title: 'Notification permission not granted' })
    }
  }

  useEffect(() => {
    function handleToastNotification(notification: ToastNotification) {
      const { level: status, title, description, links } = notification

      toast({
        status,
        title,
        description: (
          <Stack>
            <Text>{description}</Text>
            {links && (
              <ButtonGroup>
                {links.map(({ label, url }) => (
                  <LinkButton key={url} to={url}>
                    {label}
                  </LinkButton>
                ))}
              </ButtonGroup>
            )}
          </Stack>
        ),
      })

      sendBrowserNotification(notification)
    }

    function handleInboxMessageReceivedNotification(
      notification: InboxMessageReceivedNotification
    ) {
      handleToastNotification({
        id: notification.id,
        level: 'info',
        title: 'You Have a New Inbox Message',
        description: notification.description,
        type: 'toast',
        links: [
          {
            label: 'View Message',
            url: `messaging/${notification.messageId}`,
          },
        ],
      })
      invalidateInboxSummary()
      invalidateMessages()
    }

    async function onMessage(event: MessageEvent<string>) {
      try {
        const result = NotificationSchema.safeParse(JSON.parse(event.data))

        if (!result.success) {
          log.error('bad notifications from notifications websocket', result.error.issues)
          return
        }

        const notification = result.data

        exhaustive.tag(notification, 'type', {
          toast: (toastNote) => handleToastNotification(toastNote),
          inboxMessageReceived: (imrNote) => handleInboxMessageReceivedNotification(imrNote),
        })
      } catch (error) {
        log.error('could not parse event from event websocket', { error: getErrorMessage(error) })
      }
    }

    notificationSocket.addEventListener('message', onMessage)

    return () => {
      notificationSocket.removeEventListener('message', onMessage)
    }
  }, [toast, invalidateInboxSummary, invalidateMessages])

  return (
    <>
      <ConfirmModal
        confirmLabel="I'd like browser notifications"
        isOpen={isOpen}
        title="Browser Notifications"
        onCancel={() => {
          setBrowserNotificationsDisabled(true)
        }}
        onClose={onClose}
        onConfirm={requestPermission}
      >
        <Text>
          Would you like to enable browser notifications? This will allow us to send you important
          notifications even when you don&apos;t have this tab focused.
        </Text>
        <Text>
          If you&apos;d like browser notifications, please click the &ldquo;I&apos;d like browser
          notifications&ldquo; button, this will prompt the browser to request permission to send
          notifications, which you will also have to accept.
        </Text>
      </ConfirmModal>
    </>
  )
})

/**
 * Creating a BroadcastChannel will help to ensure only one browser
 * notification is sent, even if a user has multiple tabs open
 * pointed to our UI
 */

const broadcastChannel = new BroadcastChannel('notifications-handler')

const sentNotifications = new Set<string>()

function addSentNotification(id: string) {
  sentNotifications.add(id)

  /**
   * Delete sent notifications from the set after a minute. I don't know
   * if this is strictly necessary, but I also don't want to have to
   * debug a potential memory allocation issue down the line
   */
  setTimeout(() => {
    sentNotifications.delete(id)
  }, minutesToMilliseconds(1))
}

const BroadcastChannelMessageSchema = z.discriminatedUnion('type', [
  z.object({
    type: z.literal('notificationSent'),
    notificationId: z.string(),
  }),
])

type BroadcastChannelMessage = z.infer<typeof BroadcastChannelMessageSchema>

broadcastChannel.addEventListener('message', (e) => {
  const parsed = BroadcastChannelMessageSchema.safeParse(e.data)
  if (!parsed.success) return

  const message = parsed.data

  switch (message.type) {
    case 'notificationSent':
      /**
       * If another browser tab has already sent a notification
       * with a given ID, add it to the sent notifications list
       */
      addSentNotification(message.notificationId)
      break

    default:
      corrupt(message.type)
  }
})

function sendBrowserNotification({ id, level, title, description }: ToastNotification) {
  if (!canBrowserNotify || !document.hidden || Notification.permission !== 'granted') {
    return
  }

  /**
   * Using a set timeout here so that when a toast notification is received, when it's
   * displayed is slightly randomized. This gives time for the first browser tab to
   * actually trigger the toast notification to notify the other browser tabs it has
   * displayed the toast notification.
   */
  setTimeout(() => {
    if (sentNotifications.has(id)) {
      return
    }

    broadcastChannel.postMessage({
      type: 'notificationSent',
      notificationId: id,
    } satisfies BroadcastChannelMessage)

    addSentNotification(id)

    new Notification(`${level} - ${title}`, { body: description })
  }, Math.random() * 2000)
}
