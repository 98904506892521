import { Auth0IdSchema, SenderIdSchema } from '@eigtech/messaging-types'
import { useUserContext } from '@eigtech/ui-shared-auth'
import { startCase } from 'lodash-es'

export function From({ from }: { from: string }) {
  const { PreferredUserComponent } = useUserContext()

  if (Auth0IdSchema.safeParse(from).success) {
    return <PreferredUserComponent property="from" user={from} />
  }

  if (SenderIdSchema.safeParse(from).success) {
    return from.split('|').slice(1).map(startCase).join(' - ')
  }

  return from
}
