import { CohortEnumSchema } from '@eigtech/auth0-types'
import { ApiSchema } from '@eigtech/gatekeeper-types'
import { ApiLambdalerAuthHeaderSchema } from '@eigtech/lambdaler-api-gateway-types'
import { CornSchema } from '@eigtech/shared-corn'
import { Handler } from 'aws-lambda'
import { z } from 'zod'

export const GetUploadUrlApiSchema = {
  headers: ApiLambdalerAuthHeaderSchema,
  params: z.object({
    cohort: CohortEnumSchema,
    entityId: CornSchema,
  }),
  response: z.object({
    fields: z.record(z.string()),
    url: z.string(),
  }),
} satisfies ApiSchema

export const GetUploadUrlRequestSchema = GetUploadUrlApiSchema.params.merge(
  GetUploadUrlApiSchema.headers
)

export type GetUploadUrlRequest = z.infer<typeof GetUploadUrlRequestSchema>
export type GetUploadUrlResponse = z.infer<typeof GetUploadUrlApiSchema.response>
export type GetUploadUrlHandler = Handler<GetUploadUrlRequest, GetUploadUrlResponse>
