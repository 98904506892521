import { useMatches, useRoutesContext } from '@eigtech/ui-shared-router'
import { useCallback, useEffect, useMemo, useRef } from 'react'
import { useGetInboxSummary } from '../api'

export type UseFaviconAlerterProps = {
  faviconId: string
}

export function useFaviconAlerter({ faviconId }: UseFaviconAlerterProps) {
  const { data: inbox } = useGetInboxSummary()
  const lastUnreadCount = useRef(inbox?.unreadMessages)

  const faviconIntervalId = useRef<NodeJS.Timeout | null>(null)
  const faviconNode = useRef((document.getElementById(faviconId) as HTMLLinkElement)!)
  const faviconHref = useRef(faviconNode.current.href)

  const resetFaviconNode = useCallback(() => {
    if (faviconIntervalId.current) {
      clearTimeout(faviconIntervalId.current)
      faviconIntervalId.current = null
    }

    faviconNode.current.href = faviconHref.current
  }, [])

  // make sure `lastUnreadCount` get set to an initial count
  useEffect(() => {
    if (!inbox || lastUnreadCount.current !== undefined) return

    lastUnreadCount.current = inbox.unreadMessages
  }, [inbox])

  const matches = useMatches()
  const { inbox: inboxRoute } = useRoutesContext()
  const isMessagingRoute = useMemo(() => {
    return matches.some((match) => match.pathname === (inboxRoute ?? defaultInboxRoute))
  }, [inboxRoute, matches])

  /**
   * update `lastUnreadCount` when viewing inbox page
   */
  useEffect(() => {
    if (!inbox || !isMessagingRoute) return

    resetFaviconNode()

    lastUnreadCount.current = inbox.unreadMessages
  }, [inbox, isMessagingRoute, resetFaviconNode])

  /**
   * when user focuses tab while viewing inbox page, reset favicon
   */
  useEffect(() => {
    function onVisibilityChange() {
      if (!isMessagingRoute) return

      if (document.visibilityState === 'visible') {
        resetFaviconNode()
      }
    }

    document.addEventListener('visibilitychange', onVisibilityChange)

    return () => {
      document.removeEventListener('visibilitychange', onVisibilityChange)
    }
  }, [isMessagingRoute, resetFaviconNode])

  /**
   * update favicon based on inbox unread count
   */
  useEffect(() => {
    if (!inbox || lastUnreadCount.current === undefined) return

    // no new unread messages
    if (inbox.unreadMessages <= lastUnreadCount.current) {
      resetFaviconNode()
      return
    }

    // don't update favicon if on inbox page and tab is currently focused
    if (isMessagingRoute && document.visibilityState === 'visible') return

    // has new unread messages
    faviconIntervalId.current = setInterval(async () => {
      const href = await icon
      if (faviconNode.current.href === href) {
        faviconNode.current.href = faviconHref.current
      } else {
        faviconNode.current.href = href
      }
    }, 1000)

    return () => {
      if (!faviconIntervalId.current) return

      resetFaviconNode()
    }
  }, [inbox, isMessagingRoute, resetFaviconNode])
}

const defaultInboxRoute = '/messaging/inbox'

/**
 * make favicon
 */

const convertSvgToPng = (svgDataUri: string, size: number) =>
  new Promise<string>((resolve) => {
    const image = new Image()

    image.addEventListener('load', () => {
      const canvas = document.createElement('canvas')

      canvas.setAttribute('width', `${size}px`)
      canvas.setAttribute('height', `${size}px`)

      const context = canvas.getContext('2d')
      if (!context) throw new Error()
      context.drawImage(image, 0, 0, size, size)

      const dataUri = canvas.toDataURL('image/png')
      resolve(dataUri)
    })

    image.src = svgDataUri
  })

const makeIcon = async (size: number) => {
  const icon = `<svg stroke="currentColor" fill="currentColor" stroke-width="0" width="${size}px" height="${size}px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0z"></path><path d="M22 8.98V18c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2h10.1c-.06.32-.1.66-.1 1 0 1.48.65 2.79 1.67 3.71L12 11 4 6v2l8 5 5.3-3.32c.54.2 1.1.32 1.7.32 1.13 0 2.16-.39 3-1.02zM16 5c0 1.66 1.34 3 3 3s3-1.34 3-3-1.34-3-3-3-3 1.34-3 3z"></path></svg>`
  const svgDataBase64 = btoa(icon)
  const svgDataUri = `data:image/svg+xml;charset=utf-8;base64,${svgDataBase64}`
  const dataUri = await convertSvgToPng(svgDataUri, size)

  return dataUri
}

// const sizes = [16, 32]
// const icons = sizes.map((size) => makeIcon(size))

const icon = makeIcon(32)
