import { CohortEnumSchema } from '@eigtech/auth0-types'
import { ApiSchema } from '@eigtech/gatekeeper-types'
import { CornSchema } from '@eigtech/shared-corn'
import { Handler } from 'aws-lambda'
import { z } from 'zod'

export const ExportImagesApiSchema = {
  params: z.object({
    entityId: CornSchema,
    cohort: CohortEnumSchema,
  }),
  response: z.string(),
} satisfies ApiSchema

export const ExportImagesRequestSchema = ExportImagesApiSchema.params

export type ExportImagesRequest = z.infer<typeof ExportImagesRequestSchema>
export type ExportImagesResponse = z.infer<typeof ExportImagesApiSchema.response>
export type ExportImagesHandler = Handler<ExportImagesRequest, ExportImagesResponse>
