import { z } from 'zod'

export const ToastNotificationSchema = z.object({
  id: z.string(),
  type: z.literal('toast'),
  level: z.enum(['info', 'warning', 'success', 'error']),
  title: z.string(),
  description: z.string().optional(),
  links: z
    .object({
      label: z.string(),
      url: z.string(),
    })
    .array()
    .optional(),
})

export const InboxMessageReceivedNotificationSchema = z.object({
  id: z.string(),
  type: z.literal('inboxMessageReceived'),
  description: z.string().optional(),
  messageId: z.string(),
})

export const NotificationSchema = z.discriminatedUnion('type', [
  ToastNotificationSchema,
  InboxMessageReceivedNotificationSchema,
])

export const NotificationRequestBodySchema = z.object({
  recipients: z.literal('all').or(z.string().array()),
  notification: z.discriminatedUnion('type', [
    ToastNotificationSchema.omit({ id: true }),
    InboxMessageReceivedNotificationSchema.omit({ id: true }),
  ]),
})

export type ToastNotification = z.infer<typeof ToastNotificationSchema>
export type InboxMessageReceivedNotification = z.infer<
  typeof InboxMessageReceivedNotificationSchema
>

export type Notification = z.infer<typeof NotificationSchema>
export type NotificationRequestBody = z.infer<typeof NotificationRequestBodySchema>
