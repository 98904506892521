import { ApiSchema } from '@eigtech/gatekeeper-types'
import { PortalUserSchema } from '@eigtech/portals-types'
import { Handler } from 'aws-lambda'
import z from 'zod'

export const GetEstimatorUserRequestPathSchema = z.object({
  auth0Id: z.string(),
  email: z.string(),
})

export const GetEstimatorUserRequestSchema = GetEstimatorUserRequestPathSchema
export const GetEstimatorUserResponseSchema = PortalUserSchema.array()

export type GetEstimatorUserRequest = z.infer<typeof GetEstimatorUserRequestSchema>
export type GetEstimatorUserRequestPath = z.infer<typeof GetEstimatorUserRequestPathSchema>
export type GetEstimatorUserResponse = z.infer<typeof GetEstimatorUserResponseSchema>

export interface GetEstimatorUserHandler
  extends Handler<GetEstimatorUserRequest, GetEstimatorUserResponse> {}

export const GetEstimatorUserApiSchema = {
  params: GetEstimatorUserRequestPathSchema,
  response: GetEstimatorUserResponseSchema,
} satisfies ApiSchema
