import { CohortEnumSchema } from '@eigtech/auth0-types'
import { ApiSchema } from '@eigtech/gatekeeper-types'
import { CornSchema } from '@eigtech/shared-corn'
import { Handler } from 'aws-lambda'
import { z } from 'zod'
import { DocumentSchemaV2 } from '../aggregates/documents.js'

// list documents
export const ListDocumentsApiSchema = {
  params: z.object({
    entityId: CornSchema,
    cohort: CohortEnumSchema,
  }),
  response: z.array(DocumentSchemaV2),
} satisfies ApiSchema

export const ListDocumentsRequestSchema = ListDocumentsApiSchema.params

export type ListDocumentsRequest = z.infer<typeof ListDocumentsRequestSchema>
export type ListDocumentsResponse = z.infer<typeof ListDocumentsApiSchema.response>
export type ListDocumentsHandler = Handler<ListDocumentsRequest, ListDocumentsResponse>
