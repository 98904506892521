import { HStack, Page } from '@eigtech/ui-shared-dave'
import { InboxHeader, InboxActions, InboxMessagesList } from '@eigtech/ui-shared-messaging'
import { Outlet, useParams } from '@eigtech/ui-shared-router'

export function InboxLayout() {
  const { messageId } = useParams({
    experimental_returnIntersection: true,
    strict: false,
  })

  return (
    <Page
      heading={
        <HStack justifyContent="space-between" spacing="4" w="full">
          <InboxHeader />
          <InboxActions />
        </HStack>
      }
    >
      <HStack alignItems="flex-start" spacing="4">
        <InboxMessagesList activeMessageId={messageId} />

        <Outlet />
      </HStack>
    </Page>
  )
}
