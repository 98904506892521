import { ROUTER_PATHS } from '@eigtech/ui-estimator/lib/constants'
import { MessageDetail } from '@eigtech/ui-shared-messaging'
import { getRouteApi } from '@eigtech/ui-shared-router'

const Route = getRouteApi(ROUTER_PATHS.inboxMessage.routeId)

export function Message() {
  const { messageId } = Route.useParams()

  return <MessageDetail messageId={messageId} />
}
