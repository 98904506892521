import { ApiSchema } from '@eigtech/gatekeeper-types'
import { ApiLambdalerAuthHeaderSchema } from '@eigtech/lambdaler-api-gateway-types'
import { CornSchema } from '@eigtech/shared-corn'
import { Handler } from 'aws-lambda'
import { z } from 'zod'

export const DeleteDocumentApiSchema = {
  headers: ApiLambdalerAuthHeaderSchema,
  params: z.object({
    entityId: CornSchema,
    documentCorn: z.string(),
  }),
} satisfies ApiSchema

export const DeleteDocumentRequestSchema = DeleteDocumentApiSchema.params.merge(
  DeleteDocumentApiSchema.headers
)

/**
 * Pass `description` and/or `notes`
 *
 * An empty string will set the value as `null` in the DB.
 *
 * Passing `undefined` or omitting will leave the existing value as-is.
 */
export type DeleteDocumentRequestPath = z.infer<typeof DeleteDocumentApiSchema.params>
export type DeleteDocumentRequest = z.infer<typeof DeleteDocumentRequestSchema>
export type DeleteDocumentResponse = void

export type DeleteDocumentHandler = Handler<DeleteDocumentRequest, DeleteDocumentResponse>
