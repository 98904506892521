import { GetMessageRequest, GetMessageResponse } from '@eigtech/inbox-types'
import { contextualGet, queryOptions, useQuery, useQueryClient } from '@eigtech/ui-shared-api'
import { useCallback } from 'react'
import { inboxBasePath, inboxQueryKeys } from './constants'
import { useGetUserInboxId } from './useGetUserInboxId'

const getMessage = async ({ inboxId, messageId }: GetMessageRequest) =>
  contextualGet<GetMessageResponse>(`${inboxBasePath}/${inboxId}/${messageId}`)

export const getMessageQueryOptions = (request: GetMessageRequest) =>
  queryOptions({
    queryKey: inboxQueryKeys.detail(request.messageId),
    queryFn: () => getMessage({ ...request }),
    enabled: !!(request.inboxId && request.messageId),
  })

export function useGetMessage(request: Omit<GetMessageRequest, 'inboxId'>) {
  const inboxId = useGetUserInboxId()

  return useQuery(getMessageQueryOptions({ ...request, inboxId }))
}

export function useInvalidateMessage() {
  const queryClient = useQueryClient()

  const invalidateMessage = useCallback(
    ({ messageId }: Pick<GetMessageRequest, 'messageId'>) => {
      return queryClient.invalidateQueries({
        queryKey: inboxQueryKeys.detail(messageId),
        refetchType: 'all',
      })
    },
    [queryClient]
  )

  return invalidateMessage
}
