import { GetInboxSummaryRequest, GetInboxSummaryResponse } from '@eigtech/inbox-types'
import { contextualGet, useQuery, useQueryClient } from '@eigtech/ui-shared-api'
import { minutesToMilliseconds } from '@eigtech/ui-shared-dates'
import { inboxBasePath, inboxQueryKeys } from './constants'
import { useGetUserInboxId } from './useGetUserInboxId'

const getInboxSummary = ({ inboxId }: GetInboxSummaryRequest) =>
  contextualGet<GetInboxSummaryResponse>(`${inboxBasePath}/summary/${inboxId}`)

export function useGetInboxSummary() {
  const inboxId = useGetUserInboxId()

  return useQuery({
    queryKey: inboxQueryKeys.summary(),
    queryFn: () => getInboxSummary({ inboxId }),
    enabled: !!inboxId,
    refetchInterval: minutesToMilliseconds(10),
    refetchIntervalInBackground: true,
  })
}

export function useInvalidateInboxSummary() {
  const queryClient = useQueryClient()

  return () => {
    queryClient.invalidateQueries({ queryKey: inboxQueryKeys.summary(), refetchType: 'all' })
  }
}
