import { Button, ButtonGroup, Tooltip, useToast } from '@eigtech/ui-shared-dave'
import { MdError } from 'react-icons/md'
import { useArchiveAllMessages, useMarkAllMessagesAsRead } from '../api'

export function InboxActions() {
  const {
    mutateAsync: markAllMessagesAsRead,
    isPending: isMarkingAsRead,
    isError: isMarkAsReadError,
  } = useMarkAllMessagesAsRead()

  const {
    mutateAsync: archiveAllMessages,
    isPending: isArchiving,
    isError: isArchiveError,
  } = useArchiveAllMessages()

  const toast = useToast()

  return (
    <ButtonGroup isDisabled={isMarkingAsRead || isArchiving} size="sm" spacing="1">
      <Tooltip isDisabled={!isMarkAsReadError} label="Could not mark all messages as read">
        <Button
          colorScheme={isMarkAsReadError ? 'red' : undefined}
          isLoading={isMarkingAsRead}
          leftIcon={isMarkAsReadError ? <MdError /> : undefined}
          onClick={async () => {
            await markAllMessagesAsRead()
            toast({ status: 'success', title: 'Successfully marked all messages as read' })
          }}
        >
          Mark All Read
        </Button>
      </Tooltip>

      <Tooltip isDisabled={!isArchiveError} label="Could not archive all messages">
        <Button
          colorScheme={isArchiveError ? 'red' : undefined}
          isLoading={isArchiving}
          leftIcon={isArchiveError ? <MdError /> : undefined}
          onClick={async () => {
            await archiveAllMessages()
            toast({ status: 'success', title: 'Successfully archived all messages' })
          }}
        >
          Archive All
        </Button>
      </Tooltip>
    </ButtonGroup>
  )
}
