import { IssueSdkTokenRequest, IssueSdkTokenResponse } from '@eigtech/meetings-types'
import { contextualPost, queryOptions, useQuery, useQueryClient } from '@eigtech/ui-shared-api'
import { meetingsBasePath, meetingsQueryKeys } from './constants'

const defaultInterval = 30 * 60 * 1000

const getTokenRefetchInterval = (expirationDate?: Date): number =>
  expirationDate
    ? Math.min(expirationDate.valueOf() - Date.now().valueOf(), defaultInterval)
    : defaultInterval

export const getSdkToken = ({ entityId, startTime }: IssueSdkTokenRequest) =>
  contextualPost<IssueSdkTokenResponse>(`${meetingsBasePath}/sdk-token/${entityId}/${startTime}`)

export const getSdkTokenQueryOptions = ({ entityId, startTime }: IssueSdkTokenRequest) =>
  queryOptions({
    queryKey: meetingsQueryKeys.sdkToken({ entityId, startTime }),
    queryFn: async () => {
      const response = await getSdkToken({ entityId, startTime })
      return {
        accessKeyId: response.accessKeyId!,
        expiration: new Date(response.expiration),
        secretAccessKey: response.secretAccessKey!,
        sessionToken: response.sessionToken,
      }
    },
    enabled: !!(entityId && startTime),
    refetchInterval: (query) => getTokenRefetchInterval(query.state.data?.expiration),
  })

export const useGetSdkToken = (request: IssueSdkTokenRequest) =>
  useQuery(getSdkTokenQueryOptions(request))

export function useInvalidateSdkToken() {
  const queryClient = useQueryClient()

  return function useInvalidateSdkToken(request: IssueSdkTokenRequest) {
    queryClient.invalidateQueries({ queryKey: meetingsQueryKeys.sdkToken(request) })
  }
}
