import { format, isValid } from 'date-fns'
import ms from 'ms'

export function baseFormatStringAsDate(
  value: string | number | Date | undefined | null,
  dateFormat?: string
) {
  if (!value) return '(Empty)'
  if (value instanceof Date && !isValid(value)) return '(Invalid Date)'

  const formatString = dateFormat ?? 'MM/dd/yyyy'
  try {
    return format(new Date(value), formatString)
  } catch (error) {
    // log.warn(error);
  }

  // casting value in order to help Typescript. If value is an
  // instance of Date or number, it will have already returned above.
  const normalizedValue = (value as string).replace('-', '')
  const isCorrectLength = normalizedValue.length === 8
  const onlyContainsNumbers = /^[0-9]*$/.test(normalizedValue)

  if (isCorrectLength && onlyContainsNumbers) {
    const year = normalizedValue.substring(0, 4)
    const month = normalizedValue.substring(4, 6)
    const day = normalizedValue.substring(6, 8)

    const dateTimeString = `${year}-${month}-${day}`

    return format(new Date(dateTimeString), formatString)
  }

  return value as string
}

// just minimal validation
export const validateDateInRangeOfCurrentDate = (
  dateStr: string,
  {
    maxDateMsQuery = '0d',
    minDateMsQuery = '0d',
  }: {
    maxDateMsQuery: string
    minDateMsQuery: string
  }
): boolean => {
  const targetDate = new Date(dateStr)
  const minDate = new Date(new Date().getTime() + ms(minDateMsQuery))
  const maxDate = new Date(new Date().getTime() + ms(maxDateMsQuery))

  if (minDate > maxDate) {
    throw new Error('min date is above max date')
  }

  // Validate the date range
  return targetDate >= minDate && targetDate <= maxDate
}

export function getDateForFilename(date: Date | number = new Date()) {
  return format(date, "yyyy-MM-dd-'T'HHmmss")
}
