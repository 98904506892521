import { ArchiveMessageRequest, ArchiveMessageResponse } from '@eigtech/inbox-types'
import { contextualPost, useMutation } from '@eigtech/ui-shared-api'
import { asyncForEach, wait } from '@eigtech/ui-shared-dave'
import { chunk } from 'lodash-es'
import { inboxBasePath } from './constants'
import { useInvalidateInboxSummary } from './getInboxSummary'
import { useInvalidateMessage } from './getMessage'
import { useInvalidateMessages } from './getMessages'
import { useGetUserInboxId } from './useGetUserInboxId'

const archiveMessage = ({ inboxId, messageId }: ArchiveMessageRequest) =>
  contextualPost<ArchiveMessageResponse>(`${inboxBasePath}/${inboxId}/${messageId}/archive`, null, {
    responseType: 'none',
  })

export function useArchiveMessage() {
  const inboxId = useGetUserInboxId()

  const invalidateMessage = useInvalidateMessage()
  const invalidateMessages = useInvalidateMessages()
  const invalidateInboxSummary = useInvalidateInboxSummary()

  return useMutation({
    mutationFn: ({ messageId }: Pick<ArchiveMessageRequest, 'messageId'>) =>
      archiveMessage({ inboxId, messageId }),
    onSuccess(__data, { messageId }) {
      setTimeout(() => {
        invalidateMessage({ messageId })
        invalidateMessages()
        invalidateInboxSummary()
      }, 100)
    },
  })
}

export function useArchiveMessages() {
  const inboxId = useGetUserInboxId()

  const invalidateMessage = useInvalidateMessage()
  const invalidateMessages = useInvalidateMessages()
  const invalidateInboxSummary = useInvalidateInboxSummary()

  return useMutation({
    async mutationFn({ messageIds }: { messageIds: string[] }) {
      const batchedMessages = chunk(messageIds, 10)

      await asyncForEach(batchedMessages, async (batch) => {
        const promises = batch.map((messageId) => archiveMessage({ inboxId, messageId }))
        await Promise.all(promises)
        await wait(100)
      })
    },
    onSuccess(__data, { messageIds }) {
      invalidateMessages()
      invalidateInboxSummary()

      messageIds.forEach((messageId) => {
        invalidateMessage({ messageId })
      })
    },
  })
}
