import z from 'zod'
import { RecipientIdSchema, SenderIdSchema } from './messageIds'

export const MessageSchema = z.object({
  sender: SenderIdSchema,
  recipients: RecipientIdSchema.array(),
  subject: z.string(),
  body: z.string(),
  bodyFormat: z.enum(['text', 'markdown']),
  context: z.record(z.string()),
  messageVersion: z.literal(1),
})

export const SentMessageSchema = MessageSchema.merge(
  z.object({
    sentDate: z.string().datetime(),
  })
)

export const DatabaseMessageSchema = SentMessageSchema.merge(
  z.object({
    PK: z.string(),
    SK: z.string(),
  })
)

export type Message = z.infer<typeof MessageSchema>
export type SentMessage = z.infer<typeof SentMessageSchema>
export type DatabaseMessage = z.infer<typeof DatabaseMessageSchema>
