import {
  FileRoutesByPath,
  RegisteredRouter,
  RoutePaths,
  RoutesContext,
} from '@eigtech/ui-shared-router'

export const ROUTER_PATHS = {
  home: {
    routeId: '/',
    route: '/',
    url: '/',
  },

  claims: {
    routeId: '/claims/',
    route: '/claims',
    url: '/claims',
  },

  claimDetail: {
    routeId: '/claims/$claimNumber',
    route: '/claims/$claimNumber',
    url: (claimNumber: string) => `/claims/${claimNumber}`,
  },
  claimDetailInfo: {
    routeId: '/claims/$claimNumber/_layout/info',
    route: '/claims/$claimNumber/info',
    url: (claimNumber: string) => `/claims/${claimNumber}/info`,
  },
  claimDetailInspections: {
    routeId: '/claims/$claimNumber/_layout/inspections',
    route: '/claims/$claimNumber/inspections',
    url: (claimNumber: string) => `/claims/${claimNumber}/inspections`,
  },
  claimDetailEstimates: {
    routeId: '/claims/$claimNumber/_layout/estimates',
    route: '/claims/$claimNumber/estimates',
    url: (claimNumber: string) => `/claims/${claimNumber}/estimates`,
  },
  claimDetailEstimateDetail: {
    routeId: '/claims/$claimNumber/_layout/estimates/$estimateId',
    route: '/claims/$claimNumber/estimates/$estimateId',
    url: ({ claimNumber, estimateId }: { claimNumber: string; estimateId: string }) =>
      `/claims/${claimNumber}/estimates/${estimateId}`,
  },
  claimDetailMap: {
    routeId: '/claims/$claimNumber/_layout/info/map',
    route: '/claims/$claimNumber/info/map',
    url: (claimNumber: string) => `/claims/${claimNumber}/info/map`,
  },
  claimDetailTimeline: {
    routeId: '/claims/$claimNumber/_layout/timeline',
    route: '/claims/$claimNumber/timeline',
    url: (claimNumber: string) => `/claims/${claimNumber}/timeline`,
  },
  claimDetailCallbacks: {
    routeId: '/claims/$claimNumber/_layout/callbacks',
    route: '/claims/$claimNumber/callbacks',
    url: (claimNumber: string) => `/claims/${claimNumber}/callbacks`,
  },
  claimDetailContacts: {
    routeId: '/claims/$claimNumber/_layout/contacts',
    route: '/claims/$claimNumber/contacts',
    url: (claimNumber: string) => `/claims/${claimNumber}/contacts`,
  },
  claimDetailDocuments: {
    routeId: '/claims/$claimNumber/_layout/documents',
    route: '/claims/$claimNumber/documents',
    url: (claimNumber: string) => `/claims/${claimNumber}/documents`,
  },
  claimDetailLossReport: {
    routeId: '/claims/$claimNumber/_layout/loss-report',
    route: '/claims/$claimNumber/loss-report',
    url: (claimNumber: string) => `/claims/${claimNumber}/loss-report`,
  },
  claimDetailMedia: {
    routeId: '/claims/$claimNumber/_layout/media',
    route: '/claims/$claimNumber/media',
    url: (claimNumber: string) => `/claims/${claimNumber}/media`,
  },
  claimDetailMeetings: {
    routeId: '/claims/$claimNumber/_layout/meetings',
    route: '/claims/$claimNumber/meetings',
    url: (claimNumber: string) => `/claims/${claimNumber}/meetings`,
  },
  claimDetailMeeting: {
    routeId: '/claims/$claimNumber/_layout/meetings/$meetingStartTime/$meetingId',
    route: '/claims/$claimNumber/meetings/$meetingStartTime/$meetingId',
    url: ({
      claimNumber,
      meetingId,
      meetingStartTime,
    }: {
      claimNumber: string
      meetingStartTime: string
      meetingId: string
    }) => `/claims/${claimNumber}/meetings/${meetingStartTime}/${meetingId}`,
  },
  claimDetailNewMeeting: {
    routeId: '/claims/$claimNumber/_layout/meetings/new',
    route: '/claims/$claimNumber/meetings/new',
    url: (claimNumber: string) => `/claims/${claimNumber}/meetings/new`,
  },
  claimDetailNewMeetingFromVideoUpload: {
    routeId: '/claims/$claimNumber/_layout/meetings/new-from-upload',
    route: '/claims/$claimNumber/meetings/new-from-upload',
    url: (claimNumber: string) => `/claims/${claimNumber}/meetings/new-from-upload`,
  },
  claimDetailMeetingUpload: {
    routeId: '/claims/$claimNumber/_layout/meetings/$meetingStartTime/$meetingId/upload',
    route: '/claims/$claimNumber/meetings/$meetingStartTime/$meetingId/upload',
    url: ({
      claimNumber,
      meetingId,
      meetingStartTime,
    }: {
      claimNumber: string
      meetingStartTime: string
      meetingId: string
    }) => `/claims/${claimNumber}/meetings/${meetingStartTime}/${meetingId}/upload`,
  },
  claimDetailNotes: {
    routeId: '/claims/$claimNumber/_layout/notes',
    route: '/claims/$claimNumber/notes',
    url: (claimNumber: string) => `/claims/${claimNumber}/notes`,
  },
  claimDetailUploadDocument: {
    routeId: '/claims/$claimNumber/_layout/upload',
    route: '/claims/$claimNumber/upload',
    url: (claimNumber: string) => `/claims/${claimNumber}/upload`,
  },

  callbacks: {
    routeId: '/callbacks/',
    route: '/callbacks',
    url: '/callbacks',
  },
  documentation: {
    routeId: '/documentation/',
    route: '/documentation',
    url: '/documentation',
  },
  documentationDetail: {
    routeId: '/documentation/_layout/$slug',
    route: '/documentation/$slug',
    url: (slug: string) => `/documentation/${slug}`,
  },
  meeting: {
    routeId: '/meeting',
    route: '/meeting',
    url: '/meeting',
  },
  inbox: {
    routeId: '/messaging/_layout/',
    route: '/messaging',
    url: '/messaging',
  },
  inboxMessage: {
    routeId: '/messaging/_layout/$messageId',
    route: '/messaging/$messageId',
    url: ({ messageId }: { messageId: string }) => `/messaging/${messageId}`,
  },
  inspectionsSchedule: {
    routeId: '/schedules/inspections/claims',
    route: '/schedules/inspections/claims',
    url: '/schedules/inspections/claims',
  },
} satisfies Record<
  string,
  {
    route: RoutePaths<RegisteredRouter['routeTree']>
    routeId: keyof FileRoutesByPath
    url: string | ((...args: any[]) => string)
  }
>

export const ROUTES_CONTEXT: RoutesContext = {
  home: ROUTER_PATHS.home.url,
  claims: ROUTER_PATHS.claims.url,
  activeCallbacks: ROUTER_PATHS.callbacks.url,
  documentation: ROUTER_PATHS.documentation.url,

  inbox: ROUTER_PATHS.inbox.url,
  inboxMessage: ROUTER_PATHS.inboxMessage.url,

  claimDetail: ROUTER_PATHS.claimDetail.url,
  claimDetailCallbacks: ROUTER_PATHS.claimDetailCallbacks.url,
  claimDetailContacts: ROUTER_PATHS.claimDetailContacts.url,
  claimDetailDocuments: ROUTER_PATHS.claimDetailDocuments.url,
  claimDetailEstimateDetail: ROUTER_PATHS.claimDetailEstimateDetail.url,
  claimDetailEstimates: ROUTER_PATHS.claimDetailEstimates.url,
  claimDetailInfo: ROUTER_PATHS.claimDetailInfo.url,
  claimDetailMeetings: ROUTER_PATHS.claimDetailMeetings.url,
  claimDetailNewMeeting: ROUTER_PATHS.claimDetailNewMeeting.url,
  claimDetailMeeting: ROUTER_PATHS.claimDetailMeeting.url,
  claimDetailNotes: ROUTER_PATHS.claimDetailNotes.url,
  claimDetailMedia: ROUTER_PATHS.claimDetailMedia.url,
  claimDetailUploadDocument: ROUTER_PATHS.claimDetailUploadDocument.url,
}
