import { ApiSchema } from '@eigtech/gatekeeper-types'
import { ApiLambdalerAuthHeaderSchema } from '@eigtech/lambdaler-api-gateway-types'
import { CornSchema } from '@eigtech/shared-corn'
import { Handler } from 'aws-lambda'
import z from 'zod'
import { MeetingDetailsSchema } from '../base/meetingDetails.js'

export const ListMeetingsApiSchema = {
  headers: ApiLambdalerAuthHeaderSchema,
  params: z.object({
    entityId: CornSchema,
  }),
  response: z.array(MeetingDetailsSchema),
} satisfies ApiSchema

export const ListMeetingsRequestSchema = ListMeetingsApiSchema.params.merge(
  ListMeetingsApiSchema.headers
)

export type ListMeetingsRequest = z.infer<typeof ListMeetingsRequestSchema>
export type ListMeetingsResponse = z.infer<typeof ListMeetingsApiSchema.response>
export interface ListMeetingsHandler extends Handler<ListMeetingsRequest, ListMeetingsResponse> {}
