import { GetInboxRequest } from '@eigtech/inbox-types'
import { makeBasicQueryKeys } from '@eigtech/ui-shared-api'

export const inboxBasePath = 'inbox'

const inboxBaseQueryKey = makeBasicQueryKeys('inbox')

export const inboxQueryKeys = {
  ...inboxBaseQueryKey,
  filteredList: ({ previewLength, sortByRead, ...__request }: GetInboxRequest) => [
    ...inboxQueryKeys.list(),
    { previewLength, sortByRead },
  ],
  summary: () => [...inboxQueryKeys.all(), 'summary'],
}
