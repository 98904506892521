import { Handler } from 'aws-lambda'
import z from 'zod'
import { GroupIdSchema, RecipientIdSchema } from '../../entity'
import { ApiSchema } from '@eigtech/gatekeeper-types'

export const RemoveRecipientFromMessageGroupRequestSchema = z.object({
  groupId: GroupIdSchema,
  recipientId: RecipientIdSchema,
})

export const RemoveRecipientFromMessageGroupResponseSchema = z.void()

export type RemoveRecipientFromMessageGroupRequest = z.infer<
  typeof RemoveRecipientFromMessageGroupRequestSchema
>

export type RemoveRecipientFromMessageGroupResponse = z.infer<
  typeof RemoveRecipientFromMessageGroupResponseSchema
>

export interface RemoveRecipientFromMessageGroupHandler
  extends Handler<
    RemoveRecipientFromMessageGroupRequest,
    RemoveRecipientFromMessageGroupResponse
  > {}

export const RemoveRecipientFromMessageGroupApiSchema: ApiSchema = {
  body: RemoveRecipientFromMessageGroupRequestSchema,
}
