export const DATE_INPUT_FORMAT = 'yyyy-MM-dd'

export const DATE_TIME_INPUT_FORMAT = `yyyy-MM-dd'T'HH:mm`

export const STANDARD_DATE_FORMAT = 'MM/dd/yyyy'
export const STANDARD_TIME_FORMAT = 'h:mm aa'

export const STANDARD_DATE_TIME_FORMAT = 'MM/dd/yyyy hh:mm aa'

export const ISO_FORMAT = `yyyy-MM-dd'T'HH:mm:ssX`
