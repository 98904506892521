import { createFileRoute } from '@eigtech/ui-shared-router'

export const Route = createFileRoute('/messaging/_layout/')({
  component: Inbox,
})

function Inbox() {
  // This is intentional
  return <></>
}
