import z from 'zod'

export const activeMeetingKeysProperty = 'activeMeetingJsonKeys'

export const ActiveMeetingRecordSchema = z.object({
  PK: z.string(),
  SK: z.string(),
  [activeMeetingKeysProperty]: z.set(z.string()).optional(),
})

export type ActiveMeetingRecord = z.infer<typeof ActiveMeetingRecordSchema>
