import { CohortEnumSchema } from '@eigtech/auth0-types'
import { ApiSchema } from '@eigtech/gatekeeper-types'
import { Handler } from 'aws-lambda'
import { z } from 'zod'
import { DocumentSchemaV2 } from '../aggregates/index.js'

export const AddDocumentFromS3ApiSchema = {
  body: DocumentSchemaV2.pick({
    type: true,
    parentFilename: true,
    entityId: true,
    filename: true,
    notes: true,
    source: true,
    externalSystemId: true,
  }).merge(
    z.object({
      description: z.string().optional(),
      bucketName: z.string(),
      key: z.string(),
      userCohorts: CohortEnumSchema.array().optional(),
    })
  ),
  response: DocumentSchemaV2,
} satisfies ApiSchema

export const AddDocumentFromS3RequestSchema = AddDocumentFromS3ApiSchema.body
export const AddDocumentFromS3ResponseSchema = AddDocumentFromS3ApiSchema.response

export type AddDocumentFromS3Request = z.infer<typeof AddDocumentFromS3RequestSchema>
export type AddDocumentFromS3Response = z.infer<typeof AddDocumentFromS3ResponseSchema>
export type AddDocumentFromS3Handler = Handler<AddDocumentFromS3Request, AddDocumentFromS3Response>
