import { ApiSchema } from '@eigtech/gatekeeper-types'
import { ApiLambdalerAuthHeaderSchema } from '@eigtech/lambdaler-api-gateway-types'
import { CornSchema } from '@eigtech/shared-corn'
import { Handler } from 'aws-lambda'
import { z } from 'zod'
import { DocumentSchemaV2 } from '../aggregates/index.js'

export const UpdateDocumentApiSchema = {
  headers: ApiLambdalerAuthHeaderSchema,
  params: z.object({
    entityId: CornSchema,
    documentCorn: z.string(),
  }),
  body: DocumentSchemaV2.pick({
    description: true,
    notes: true,
  }).deepPartial(),
} satisfies ApiSchema

export const UpdateDocumentRequestSchema = UpdateDocumentApiSchema.body
  .merge(UpdateDocumentApiSchema.params)
  .merge(UpdateDocumentApiSchema.headers)

/**
 * Pass `description` and/or `notes`
 *
 * An empty string will set the value as `null` in the DB.
 *
 * Passing `undefined` or omitting will leave the existing value as-is.
 */
export type UpdateDocumentRequestBody = z.infer<typeof UpdateDocumentApiSchema.body>
export type UpdateDocumentRequestPath = z.infer<typeof UpdateDocumentApiSchema.params>
export type UpdateDocumentRequest = z.infer<typeof UpdateDocumentRequestSchema>
export type UpdateDocumentResponse = void

export type UpdateDocumentHandler = Handler<UpdateDocumentRequest, UpdateDocumentResponse>
