const kB = 1000
const MB = kB * kB
const GB = kB * MB

export const MAX_FILE_SIZE = 20 * MB
export const MAX_VIDEO_FILE_SIZE = 1 * GB

export const ACCEPTED_VIDEO_TYPES = ['video/mp4', 'video/quicktime']

export const ACCEPTED_VIDEO_EXTENSIONS = ['mov', 'mp4']

export const ACCEPTED_PHOTO_TYPES = [
  'image/jpeg',
  'image/png',
  // 'image/heic',
  'image/gif',
]

export const ACCEPTED_DOCUMENT_TYPES = [
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
]

export const ACCEPTED_CUSTOM_EXTENSIONS = ['.esx']

export const ACCEPTED_FILE_TYPES = [
  ...ACCEPTED_VIDEO_TYPES,
  ...ACCEPTED_PHOTO_TYPES,
  ...ACCEPTED_DOCUMENT_TYPES,
  ...ACCEPTED_CUSTOM_EXTENSIONS,
]

export const photoTypeRegex = /\.(jpg|jpeg|png|gif)$/i
export const videoTypeRegex = /\.(mp4|mov)$/i
export const mediaTypeRegex = /\.(jpg|jpeg|png|gif|mp4|mov)$/i

export const isVideoFilename = (filename: string) =>
  ACCEPTED_VIDEO_EXTENSIONS.includes(filename.toLowerCase().split('.')?.pop() ?? '')
