import { ChimeChannelMessageTypes } from '@eigtech/meetings-types'
import { FileUpload, useCohortContext } from '@eigtech/ui-shared-api'
import { useToast } from '@eigtech/ui-shared-dave'
import log from '@eigtech/ui-shared-logging'
import { useAudioVideo } from 'amazon-chime-sdk-component-library-react'
import { pick } from 'lodash-es'
import { useCallback, useEffect, useRef } from 'react'
import { useGetSdkToken, useUploadScreenshot } from '../api'
import { sendChannelMessage } from '../chime-sdk/chimeApi'
import { MeetingState, useMeetingStore } from '../context'
import { getFileFromCanvas } from '../utils'

export type UseCaptureImageFromVideoProps = {
  tileId: number | undefined
  getMeta?: (image: File) => Promise<Record<string, string> | undefined>
}

export function useCaptureImageFromVideo({ tileId, getMeta }: UseCaptureImageFromVideoProps) {
  const audioVideo = useAudioVideo()

  const canvasRef = useRef<HTMLCanvasElement>(null)
  const downloadRef = useRef<HTMLAnchorElement>(null)

  const { cohort } = useCohortContext()
  const { canScreenshot, meetingDetails, participant } = useMeetingStore(stateSelector)

  if (!meetingDetails) {
    throw new Error('meeting details missing')
  }

  const { data: credentials } = useGetSdkToken({
    entityId: meetingDetails.entityId,
    startTime: meetingDetails.startTime,
  })

  const { mutateAsync: uploadScreenshot } = useUploadScreenshot({
    cohort,
    entityId: meetingDetails.entityId,
    meetingId: meetingDetails.meetingId,
  })

  useEffect(() => {
    if (!canvasRef.current) {
      log.warn('Make sure to set canvasRef in order for useCaptureImageFromVideo hook to work')
    }
    if (!downloadRef.current) {
      log.warn('Make sure to set downloadRef in order for useCaptureImageFromVideo hook to work')
    }
  }, [])

  const toast = useToast()

  const capturePhoto = useCallback(async () => {
    if (!canScreenshot) return

    if (meetingDetails.status !== 'closed' && credentials && participant) {
      // Intentionally not awaiting (don't slow anything down here)
      sendChannelMessage(
        credentials,
        meetingDetails.appInstanceArn!,
        meetingDetails.chimeChannelArn!,
        participant.auth0Id ?? participant.attendeeId ?? 'guest',
        participant.name,
        ChimeChannelMessageTypes.markScreenshot
      )
    } else {
      log.warn('Missing some parameters to send screenshot message', {
        status: meetingDetails.status,
        credentials,
        participant,
      })
    }

    if (!(canvasRef.current && downloadRef.current)) {
      throw new Error(
        'Attempting to use useCaptureImageFromVideo without adding canvas & download refs'
      )
    }

    if (!audioVideo) {
      throw new Error('useCaptureImageFromVideo needs AudioVideo provider to work')
    }

    if (tileId === undefined) {
      throw new Error('tileId required to capture photo')
    }

    const image = audioVideo.captureVideoTile(tileId)
    if (!image) {
      toast({
        status: 'warning',
        title: 'Something went wrong trying to capture photo from video.',
      })
      return
    }

    canvasRef.current.width = image.width
    canvasRef.current.height = image.height

    const canvasContext = canvasRef.current.getContext('2d')
    if (!canvasContext) {
      toast({
        status: 'warning',
        title: 'Something went wrong trying to capture photo from video.',
      })
      return
    }

    canvasContext.clearRect(0, 0, image.width, image.height)
    canvasContext.putImageData(image, 0, 0)

    const file = await getFileFromCanvas({ canvasRef, meetingId: meetingDetails.meetingId })

    const payload: FileUpload = { file, 'Content-Type': 'image/png' }

    if (getMeta) {
      payload.meta = await getMeta(file)
    }

    uploadScreenshot(payload)
  }, [
    audioVideo,
    canScreenshot,
    credentials,
    getMeta,
    meetingDetails.meetingId,
    meetingDetails.appInstanceArn,
    meetingDetails.chimeChannelArn,
    meetingDetails.status,
    participant,
    tileId,
    toast,
    uploadScreenshot,
  ])

  return { canvasRef, downloadRef, capturePhoto }
}

const stateSelector = (state: MeetingState) =>
  pick(state, ['canScreenshot', 'cohort', 'meetingDetails', 'participant'])
