import { useAuthStore } from '@eigtech/ui-shared-auth'
import PartySocket from 'partysocket'

let hasOpenedEventSocket = false
useAuthStore.subscribe((state) => {
  if (!import.meta.env.VITE_WS_URL) return

  if (!state.hasSetAccessToken || hasOpenedEventSocket) {
    return
  }

  notificationSocket.reconnect()
  hasOpenedEventSocket = true
})

export const notificationSocket = new PartySocket({
  host: import.meta.env.VITE_WS_URL ?? '',
  room: 'notifications',
  party: 'notifications',
  startClosed: true,
  query: async () => ({
    token: await useAuthStore.getState().getAccessToken(),
  }),
  maxRetries: 3,
})

let pingInterval: ReturnType<typeof setInterval>

notificationSocket.addEventListener('open', () => {
  clearInterval(pingInterval)

  pingInterval = setInterval(() => {
    notificationSocket.send('heartbeat')
  }, 2000)
})
