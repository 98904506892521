import { MarkAllAsReadRequest, MarkAllAsReadResponse } from '@eigtech/inbox-types'
import { contextualPost, useMutation } from '@eigtech/ui-shared-api'
import { inboxBasePath } from './constants'
import { useInvalidateInboxSummary } from './getInboxSummary'
import { useInvalidateMessages } from './getMessages'
import { useGetUserInboxId } from './useGetUserInboxId'

const markAllMessagesAsRead = ({ inboxId }: MarkAllAsReadRequest) =>
  contextualPost<MarkAllAsReadResponse>(`${inboxBasePath}/${inboxId}/read`)

export function useMarkAllMessagesAsRead() {
  const invalidateMessages = useInvalidateMessages()
  const invalidateInboxSummary = useInvalidateInboxSummary()

  const inboxId = useGetUserInboxId()

  return useMutation({
    mutationFn: () => markAllMessagesAsRead({ inboxId }),
    onSuccess() {
      invalidateMessages()
      invalidateInboxSummary()
    },
  })
}
