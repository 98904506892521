import { Handler } from 'aws-lambda'
import z from 'zod'
import { GroupIdSchema, RecipientIdSchema } from '../../entity'
import { ApiSchema } from '@eigtech/gatekeeper-types'

export const ListRecipientsInMessageGroupPathRequestSchema = z.object({
  groupId: GroupIdSchema,
})

export const ListRecipientsInMessageGroupRequestSchema =
  ListRecipientsInMessageGroupPathRequestSchema
export const ListRecipientsInMessageGroupResponseSchema = z.object({
  recipientIds: RecipientIdSchema.array(),
})

export type ListRecipientsInMessageGroupRequest = z.infer<
  typeof ListRecipientsInMessageGroupRequestSchema
>

export type ListRecipientsInMessageGroupResponse = z.infer<
  typeof ListRecipientsInMessageGroupResponseSchema
>

export interface ListRecipientsInMessageGroupHandler
  extends Handler<ListRecipientsInMessageGroupRequest, ListRecipientsInMessageGroupResponse> {}

export const ListRecipientsInMessageGroupApiSchema: ApiSchema = {
  params: ListRecipientsInMessageGroupPathRequestSchema,
}
