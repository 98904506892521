import z from 'zod'

export const contactBookPrefix = 'contactBook'
export const servicePrefix = 'service'
export const auth0UserPrefix = 'auth0'
export const auth0AzureAdPrefix = 'waad'
export const auth0GoogleAppsPrefix = 'google-apps'
export const auth0PasswordlessEmailPrefix = 'email'
export const messageGroupPrefix = 'group'
export const contactPrefix = 'contact'

const auth0PrefixList = `${auth0UserPrefix}|${auth0AzureAdPrefix}|${auth0GoogleAppsPrefix}|${auth0PasswordlessEmailPrefix}`
const auth0Regex = `\\b(${auth0PrefixList})[|]`
const serviceRegex = `\\b${servicePrefix}[|]`
const messageGroupRegex = `\\b${messageGroupPrefix}[|]`
const contactBookRegex = `\\b${contactBookPrefix}[|]`
const contactRegex = `\\b${contactPrefix}[|]`

export const Auth0IdSchema = z.custom<
  | `${typeof auth0UserPrefix}|${string}`
  | `${typeof auth0AzureAdPrefix}|${string}`
  | `${typeof auth0GoogleAppsPrefix}|${string}`
  | `${typeof auth0PasswordlessEmailPrefix}|${string}`
>((val) => new RegExp(`${auth0Regex}`, 'g').test(val as string))

export const GroupIdSchema = z.custom<`${typeof messageGroupPrefix}|${string}`>((val) =>
  new RegExp(`${messageGroupRegex}`, 'g').test(val as string)
)

export const ContactBookIdSchema = z.custom<`${typeof contactBookPrefix}|${string}`>((val) =>
  new RegExp(`${contactBookRegex}`, 'g').test(val as string)
)

export const ServiceIdSchema = z.custom<`${typeof servicePrefix}|${string}`>((val) =>
  new RegExp(`${serviceRegex}`, 'g').test(val as string)
)

export const ContactIdSchema = z.custom<`${typeof contactPrefix}|${string}`>((val) =>
  new RegExp(`${contactRegex}`, 'g').test(val as string)
)

export const RecipientIdPrefixEnumSchema = z.enum([
  contactBookPrefix,
  auth0UserPrefix,
  auth0AzureAdPrefix,
  auth0GoogleAppsPrefix,
  auth0PasswordlessEmailPrefix,
  messageGroupPrefix,
  contactPrefix,
])
export const RecipientIdPrefix = RecipientIdPrefixEnumSchema.Enum

export const RecipientIdSchema = Auth0IdSchema.or(GroupIdSchema)
  .or(ContactBookIdSchema)
  .or(ContactIdSchema)

export const SenderIdSchema = Auth0IdSchema.or(ServiceIdSchema)

export type Auth0Id = z.infer<typeof Auth0IdSchema>
export type GroupId = z.infer<typeof GroupIdSchema>
export type RecipientIdPrefix = z.infer<typeof RecipientIdPrefixEnumSchema>
export type RecipientId = z.infer<typeof RecipientIdSchema>
export type SenderId = z.infer<typeof SenderIdSchema>
