import { ApiSchema } from '@eigtech/gatekeeper-types'
import { ApiLambdalerAuthHeaderSchema } from '@eigtech/lambdaler-api-gateway-types'
import { CornSchema } from '@eigtech/shared-corn'
import { Handler } from 'aws-lambda'
import { z } from 'zod'

export const IssueSdkTokenApiSchema = {
  params: z.object({
    entityId: CornSchema,
    startTime: z.string().datetime(),
  }),
  headers: ApiLambdalerAuthHeaderSchema,
  response: z.object({
    accessKeyId: z.string(),
    expiration: z.string(),
    secretAccessKey: z.string(),
    sessionToken: z.string(),
  }),
} satisfies ApiSchema

export const IssueSdkTokenRequestSchema = IssueSdkTokenApiSchema.params.merge(
  IssueSdkTokenApiSchema.headers
)

export const IssueSdkTokenResponseSchema = IssueSdkTokenApiSchema.response

export type IssueSdkTokenRequest = z.infer<typeof IssueSdkTokenRequestSchema>
export type IssueSdkTokenResponse = z.infer<typeof IssueSdkTokenResponseSchema>
export type IssueSdkTokenHandler = Handler<IssueSdkTokenRequest, IssueSdkTokenResponse>
