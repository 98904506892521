import { ApiSchema } from '@eigtech/gatekeeper-types'
import { ApiLambdalerAuthHeaderSchema } from '@eigtech/lambdaler-api-gateway-types'
import { Handler } from 'aws-lambda'
import z from 'zod'
import { MeetingDetailsSchema } from '../base/meetingDetails.js'

export const GetActiveMeetingsApiSchema = {
  headers: ApiLambdalerAuthHeaderSchema,
  response: z.array(MeetingDetailsSchema),
} satisfies ApiSchema

export const GetActiveMeetingsRequestSchema = GetActiveMeetingsApiSchema.headers

export type GetActiveMeetingsRequest = z.infer<typeof GetActiveMeetingsRequestSchema>
export type GetActiveMeetingsResponse = z.infer<typeof GetActiveMeetingsApiSchema.response>
export interface GetActiveMeetingsHandler
  extends Handler<GetActiveMeetingsRequest, GetActiveMeetingsResponse> {}
