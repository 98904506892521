import z from 'zod'
import { MessageSchema, RecipientIdSchema } from '../../entity'
import { Handler } from 'aws-lambda'
import { ApiSchema } from '@eigtech/gatekeeper-types'
import { ApiLambdalerAuthHeaderSchema } from '@eigtech/lambdaler-api-gateway-types'

export const SendMessageRequestHeadersSchema = ApiLambdalerAuthHeaderSchema

export const SendMessageRequestBodySchema = MessageSchema.omit({
  sender: true,
})

export const SendMessageRequestSchema = SendMessageRequestBodySchema.merge(
  SendMessageRequestHeadersSchema
)

export const SendMessageResponseSchema = z.object({
  sent: z.object({ dateSent: z.string(), recipient: RecipientIdSchema }).array(),
  failedCount: z.number(),
})

export type SendMessageRequestBody = z.infer<typeof SendMessageRequestBodySchema>
export type SendMessageRequest = z.infer<typeof SendMessageRequestSchema>

export type SendMessageResponse = z.infer<typeof SendMessageResponseSchema>

export interface SendMessageHandler extends Handler<SendMessageRequest, SendMessageResponse> {}

export const SendMessageApiSchema: ApiSchema = {
  body: SendMessageRequestBodySchema,
  headers: SendMessageRequestHeadersSchema,
  response: SendMessageResponseSchema,
}
